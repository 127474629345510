header {
    height: auto;
    background-color: rgb(225, 223, 218);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.logo-container {
    display: flex;
    align-items: center;
    margin-left: 4.06vw;
    margin-top: 0.94vw;
    margin-bottom: 0.94vw;
    column-gap: 0.36vw;
    width: 11.77vw;
}

.logo-container img {
    width: 7vw;
}

.links-outer {
    margin-right: 3.49%;
    width: 70vw;
}

.ru {
    margin-left: 5vw;
    display: flex;
    justify-content: flex-end; /* Изменено с end на flex-end */
    align-items: flex-start; /* Изменено с start на flex-start */
    width: 4%;
}

.links-outer a {
    display: flex;
    justify-content: flex-end; /* Изменено с end на flex-end */
    align-items: flex-start; /* Изменено с start на flex-start */
    color: black;
    font-size: 1.6vw; /* Добавлены единицы измерения */
    font-weight: 400;
}

.row-eng {
    display: flex;
    justify-content: flex-end; /* Изменено с end на flex-end */
    align-items: flex-start; /* Изменено с start на flex-start */
    margin-bottom: 3.33%;

}

.header-nav ul {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    column-gap: 4%;
}

.header-nav ul li {
    text-align: center;
}

.row-eng a {
    line-height: auto;
    font-size: 0.63vw; /* Уже верно указано */
}

.row-eng button {
    font-size: 0.63vw;
}

a, span, .row-eng a {
    font-family: 'Roboto';
    line-height: auto;
    font-weight: 400;
}

a {
    font-size: 1.04vw;
    color: black;
}

.ru {
    font-weight: 500;
    font-size: 1.1vw; /* Добавлены единицы измерения */
    color: rgb(68, 88, 82);
    word-wrap: break-word;
}

.burger {
    display: none;
}

.sliding-menu {
    display: none;
}







/*                                                                 Burger Menu (480px) and Adaptive layout                                                               */


@media (max-width: 480px) {
    a {
        font-size: 2.7vw;
        color: black;
    }

    .burger {
        display: flex;
        position: relative;
        align-self: center;
        align-items: center;
        justify-content: flex-end;
        width: 7vw;
        height: 18px;
        margin-right: 6.5vw;
        cursor: pointer;
    }

    .burger span {
        height: 1.3px;
        width: 7vw;
        background-color: black;
    }

    .burger::before , .burger::after {
        content: '';
        position: absolute;
        height: 1.3px;
        width: 7vw;
        background-color: black;
    }

    .burger::before {
        top: 5px;
        height: 1px
    }

    .burger::after {
        bottom: 5px
    }


    .cl-btn-2 {
        margin-top: 5px;
        margin-left: 15px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        animation: CloseAnimation 0.3s;
    }

    .cl-btn-2 div {
        cursor: pointer;
        position: relative;
        width: 7.7vw;
        height: 7.7vw;
    }

    .cl-btn-2 .leftright {
        height: 0.7vw;
        width: 7.7vw;
        position: absolute;
        margin-top: 79%;
        background-color: black;
        border-radius: 2px;
        transform: rotate(45deg);
        transition: all .3s ease-in;
    }

    .cl-btn-2 .rightleft {
        height: 0.7vw;
        width: 7.7vw;
        position: absolute;
        margin-top: 79%;
        background-color: black;
        border-radius: 2px;
        transform: rotate(-45deg);
        transition: all .3s ease-in;
    }

    .cl-btn-2 .close-btn {
        margin: 190% 0 0 -42%;
        position: absolute;
        color: black;
        font-family: Tahoma, sans-serif;
        font-size: 3vw;
        font-weight: bold;
        text-transform: uppercase;
        transition: all .3s ease-in;
        opacity: 0;
    }

    .mobile-nav img {
        width: 8%;
        margin-left: 6%;
    }

    .mobile-btn-header {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 9.5%
    }

    .mobile-btn-header div {
        margin-left: 6%;
        width: 90px;
        font-size: 3.75vw;
    }

}
