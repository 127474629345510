.help-box {
    background-color: rgb(29, 54, 48);
    display: flex;
    color: white;
    column-gap: 5%;
    justify-content: space-between;
    padding-top: 25.5%;
    padding-left: 5%;
}

.help-outer-box {
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    position: relative;
    width: 44vw;
    margin-top: -27%;
}

.help-inner-box {
    width: auto;
    position: absolute;
    top: 27%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 15%;
    line-height: 116.7%;
}

.help-inner-box p:first-child {
    font-size: 3.125vw;
    margin-bottom: 6.5%;
}

.help-text {
    margin-bottom: 6%;
    font-size: 0.99vw;
}

.help-img-box {
    display: flex;
    justify-content: space-between;
    width: 38vw;
    position: relative;
}

.yakut-box {
    position: absolute;
    bottom: 0;
}

.help-inner-box button {
    border: 0.1vw solid white;
    padding: 3.3% 7%;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1.065vw;
    letter-spacing: 15%;
    line-height: 116.7%;
    text-transform: uppercase;
    background-color: transparent;
    color: white;
    letter-spacing: 0.1em;
}

.help-inner-box a {
    color: white;
    text-decoration: none;
}

section .mobile-btn {
    display: none;
}

@media (max-width: 1158px) {
    .help-outer-box {
        width: 56vw;
    }

    .help-box {
        padding-left: 0;
    }
}

@media (max-width: 840px) {
    .help-box {
        padding-left: 3%;
    }

    .help-inner-box :first-child {
        margin-bottom: 2.5%;
    }
    
    .help-text {
        margin-bottom: 3%;
    }

    .help-outer-box {
        width: 62vw;
    }

    .help-inner-box {
        top: 22%;
    }
}


@media (max-width: 480px) {

    section .help-box {
        height: 100%;
    }

    section .help-outer-box {
        width: 100vw;
    }

    section .help-inner-box {
        position: static;
    }

    section .help-img-box {
        display: none;
    }

    section .desktop-btn {
        display: none;
    }

    section .mobile-btn {
        display: block;
    }

    section .help-inner-box button {
        border: 0.3vw solid white;
        padding: 3.6vw 5.5vw;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 3.8vw;
        letter-spacing: 15%;
        line-height: 116.7%;
        text-transform: uppercase;
        background-color: transparent;
        color: white;
        letter-spacing: 0.1em;
    }

    section .help-inner-box a {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 3.8vw;
    }

    .help-box {
        background-color: rgb(29, 54, 48);
        color: white;
        padding-left: 0;
    }

    .help-outer-box {
        display: flex;
        justify-content: center;
        align-items: center;
        word-wrap: break-word;
        position: relative;
        width: auto;
    }

    .help-inner-box {
        width: auto;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        letter-spacing: 15%;
        line-height: 116.7%;
    }

    .help-text, .help-inner-box p {
        display: none;
    }

}