.sec1-main {
    margin: 0 auto;
}

.sec1-main p, .box-sec1_1 h1 {
    font-family: 'Roboto';
    line-height: auto;
    margin: auto;
    letter-spacing: 10%;
    margin-bottom: 1.2%;
}

.sec1-main p {
    font-size: 1.7vw;
}

.box-sec1_2 p {
    margin-bottom: 1%;
}

.text1_1 {
    color: rgb(101, 101, 101);
}

.sec1-main h1 {
    font-size: 6.81vw;
    font-weight: 500;
    text-transform: uppercase;
}

.box-sec1_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    margin-bottom: 2%;
}

.box-sec1_2 :last-child , .box-sec1_1 div :last-child {
   text-align: center;
}

.box-sec1_2 div {
    margin: 0 3%;
}

.outer-text1_2 {
    margin-top: 2%;
}

.sec1-mobile {
    display: none;
}





/*                                                                     Section1 Mobile                                                          */

@media (max-width: 480px) {

    .sec1-main {
        margin: 0 auto;
    }

    .sec1-content {
        padding: 5% 0;
    }

    .sec1-mobile {
        display: block;
        text-align: center;
    }
    
    .sec1-main p {
        font-size: 3.99vw;
        letter-spacing: 0.1em;
        margin: 0%;
    }
    
    
    .sec1-main h1 {
        font-size: 7.99vw;
        font-weight: 500;
        text-transform: uppercase;
    }
    
    .box-sec1_1 {
        margin-bottom: 0%;
    }

    .subsec1-1_1 p {
        margin-bottom: 6%;
        margin-top: 7%;
        font-weight: 700;
    }


    .subsec1-1_1 , .subsec1-1_2 {
        width: 80vw;
        word-wrap: break-word;
        margin: 0 auto;
    }
}