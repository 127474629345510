main {
    margin-bottom: 2%;
}

.landscape0 {
    width: fit-content;
    margin: 0 auto;
}

.landscape0 img {
    max-width: 100%;
    object-fit: cover;
    width: auto;
}

