.footer-box {
    /* height: 393px; */
    height: auto;
    display: flex;
    background-color: rgb(225,223,218);
    position: relative;
    justify-content: space-evenly;
    bottom: 0;
    margin: 0 auto;
    padding-top: 1.93vw;
    padding-bottom: 3.23vw;
    /* border: red solid; */
}

.narodi-i-priroda {
    background-image: url("./../../images/landscape1.jpg");
    background-size: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* border: blue solid; */
}

.narodi-i-interview {
    background-image: url("./../../images/landscape2.jpg");
    background-size: 100%;
    height: 100%;
    background-repeat: no-repeat;
    /* border: blue solid; */
}

.text-box-footer {
    padding: 4.22vw 17.19vw 5.36vw 1.46vw;
    max-width: 29.17vw;
    max-height: 15.31vw;
    color: white;
    font-size: 1.25vw;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-weight: 700;
    letter-spacing: 0.15em;
    line-height: 116.7%;
}

.text-box-footer a {
    color: white;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 0.83vw;
    font-weight: 500;
    cursor: pointer;
}

.text-box-footer p {
    margin-bottom: 0.36vw;
    line-height: 2em;
    color: white;
}

.footer-text {
    display: inline-block;
    width: 25.78vw;
    letter-spacing: 0.1em;
}

.footer-img {
    display: flex;
    padding-left: 2.08vw;
    padding-top: 1.15vw;
    column-gap: 3vw;
    justify-content: space-between;
    align-items: center;
    margin-right: 2.08vw;
    /* border: yellow solid; */
}

.footer-text {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.83vw;
    line-height: 116.7%;
    letter-spacing: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: white solid; */
}

.footer-text p {
    font-size: 0.83vw;
}

.keeper {
    max-width: 25.83vw;
    /* height: 337px; */
    word-wrap: break-word;
    padding-left: 1.04vw;
    padding-top: 0.5vw;
    /* border: black solid; */
}

.keeper :first-child , .keeper :nth-child(2), .review , .keeper :last-child {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.9vw;
    line-height: 116.7%;
    letter-spacing: 15%;
}

.keeper :nth-child(2), .review, .keeper :last-child {
    font-size: 0.83vw;
    margin-bottom: 2.19vw;
}

.keeper :first-child {
    margin-bottom: 2.19vw;
    font-size: 1.15vw;
}