.interviews-content {
    width: 80%;
    margin: 11% auto;
}

.line-div {
    display: flex;
    margin: 2% auto;
}

.line-div :nth-child(2) {
    overflow: hidden;
}

/*                                                   Interview-section-1                                               */


.interview-box-1_1 p {
 font-size: 2vw;
 text-transform: uppercase;
 width: 41.6vw;
}

.interview-box-1_2  img {
    width: 35.5vw;
    margin-top: 1%;
}

.interview-box-1_2 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    margin-bottom: 17%;
}

.interview-box-1_2 p {
    font-size: 1.7vw;
    letter-spacing: 0.1em;
}

.interview-box-1_2 :nth-child(2) {
    width: 41.8vw;
    word-wrap: break-word;
}


/*                                                   Interview-section-2                                               */

.interview-box-2_1 {
    position: relative;
    letter-spacing: 0.1em;
    margin-bottom: 24%; 
    padding-bottom: 55%;
}


.interview-box-2_1 :nth-child(1) h2, .interview-box-2_2 h2, .interview-box-2_3 h2 {
    text-transform: uppercase;
    font-size: 3.125vw;
    margin-bottom: 6%;
    letter-spacing: 0.13em;
}

.txt1-interviews, .interview-box-2_2, .interview-box-2_3 {
    width: 28vw;
    word-wrap: break-word;
}

.interview-box-2_1 :nth-child(1) p , .interview-box-2_2 p , .interview-box-2_3 p {
    font-size: 1.7vw;
}

.deer {
    position: absolute;
    width: 31.1vw;
    top: 0;
    right: 24%;
}

.landscape3 {
    width: 41.5vw;
    position: absolute;
    right: 0;
    top: 16%;
    z-index: -1;
}

.sitting {
    width: 44vw;
    margin-top: 6%;
}

.shack {
    width: 41.5vw;
    height: 29%;
    position: absolute;
    right: 0;
    top: 73%;
}

.interview-box-2_2 {
    width: 32.2vw;
    word-wrap: break-word;
    position: absolute;
    right: 0;
    top: 43%;
}

.interview-box-2_3 {
    position: absolute;
    top: 72.8%;
    width: 36.7vw;
}

.interview-box-2_2 h2 , .interview-box-2_3 h2 {
    margin-bottom: 2%;
}


/*                                                   Interview-section-3                                               */

.interview-box-3_1 h2 {
    text-transform: uppercase;
    font-size: 3.125vw;
    letter-spacing: 0.13em;
}

.interview-box-3_1 p {
    font-size: 1.7vw;
}




@media (max-width: 1176px) {
    .sitting {
        margin-top: 5%  ;
    }
}


@media (max-width: 908px) {
    .sitting {
        margin-top: 4%;
    }
}

@media (max-width: 609px) {
    .sitting {
        margin-top: 1%;
    }
}

@media (max-width: 600px) {
    .interview-box-2_2 {
        top: 42%;
    }
}

@media (max-width: 492px) {
    .interview-box-2_2 {
        top: 41%;
        right: 1%;
    }
}



/*                                                                     Interviews Mobile                                                          */


@media (max-width: 480px) {

    
    .interviews-content {
        width: 86%;
        margin: 27% auto;
        margin-top: 13%;
    }
    
    .line-div {
        display: flex;
        margin: 10% auto;
    }

    .line-div :first-child {
        display: none;
    }
    
    
    /*                                                   Interview-section-1                                               */
    
    
    .interview-box-1_1 p {
     max-width: 52.8vw;
     font-size: 7.5vw;
     width: auto;
    }
    
    .interview-box-1_2  img {
       width: 86vw;
    }
    
    .interview-box-1_2 {
        display: block;
        margin-bottom: 22%;
    }
    
    .interview-box-1_2 p {
        font-size: 3.99vw;
        letter-spacing: 0.1em;
    }
    
    .interview-box-1_2 :nth-child(2) {
        word-wrap: break-word;
        margin-top: 13%;
        width: auto;
    }
    
    
    /*                                                   Interview-section-2                                               */
    
    .interview-box-2_1 {
        letter-spacing: 0.1em;
        margin-bottom: 0;
        height: auto;
        padding-bottom: 0;
    }
    
    .interview-box-2_1 :nth-child(1) h2, .interview-box-2_2 h2, .interview-box-2_3 h2 {
        text-transform: uppercase;
        font-size: 8.27vw;
        margin-bottom: 9%;
        font-weight: 500;
        letter-spacing: 0.13em;
    }
    
    .interview-box-2_1 :nth-child(1) {
        width: auto;
        word-wrap: break-word;
    }
    
    .interview-box-2_1 :nth-child(1) p , .interview-box-2_2 p , .interview-box-2_3 p {
        font-size: 4vw;
    }
    
    .deer , .landscape3, .shack {
        width: 100%;
        height: auto;
        position: static;
        margin: 13% auto;
    }

    .sitting {
        display: none;
    }
    
    .interview-box-2_2 {
        width: auto;
        word-wrap: break-word;
        position: static;
    }
    
    .interview-box-2_3 {
        margin: 0;
        position: static;
        width: auto;
    }
    
    
    /*                                                   Interview-section-3                                               */
    
    .interview-box-3_1 h2 {
        text-transform: uppercase;
        font-size: 7.27vw;
        letter-spacing: 0.13em;
        font-weight: 500;
    }

    .interview-box-3_1 {
        margin-top: 12%;
    }

    .interview-box-3_1 .line-div {
        margin: 7% auto;
    }
    
    .interview-box-3_1 p {
        font-size: 4vw;
    }

}