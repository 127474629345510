/* .nature-sec1 img, .nature-sec2 img, .nature-sec3 img, .nature-sec4 img {
    max-width: 100%;
    height: auto;
} */

.nature-sec1-inner , .nature-sec2-inner , .nature-sec3-inner , .nature-sec4-inner {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.nature-sec1-inner :nth-child(3) img {
    width: 100%;
}

.nature-sec2 .box-hide, .nature-sec3 .box-hide {
    position: relative;
    height: fit-content;
    width: fit-content;
}

.absolute-wrapper {
    position: absolute;
    right: 0;
    top: 16%;
}

.nature-sec3-inner .absolute-wrapper img:last-child {
    position: absolute;
    z-index: 2;
    bottom: -29%;
    left: -32%;
}

.img3-4-sec3-nature img:last-child {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: -59%;
}

.unifying-nature .box-hide :last-child {
    position: absolute;
    z-index: 2;
    top: 33%;
    left: -45%;
}

/* .nature-sec1, .nature-sec2, .nature-sec3, .nature-sec4 {
    border: blue solid;
} */


.nature-sec1 .title-nature {
    margin-bottom: 4.9vw;
    margin-top: 6.75vw;
}

.title-nature {
    /* border: black solid; */
    width: 50%;
    max-width: 56.81vw;
    margin: 4.9vw auto auto 1.78vw;
    line-height: 116.7%;
    letter-spacing: 15%;
    font-size: 3.125vw;
    text-transform: uppercase;
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.nature-sec2 .title-nature {
    width: 100%;
    max-width: 80.5vw;
    margin-top: 5.82vw;
}

.nature-sec3 .title-nature {
    width: 80%;
    max-width: 71.022vw;
}

.nature-sec4 .title-nature {
    width: 90%;
    max-width: 78.125vw;
}

.line-nature img {
    width: 100%;
}

.nature-sec1-inner div:nth-child(3) {
    /* border: rebeccapurple solid; */
    margin-top: 4.119vw;
}

.nature-sec1 .line-nature {
    height: 0.142vw;
}

.text-nature {
    margin: 4.62vw auto 2.27vw 1.78vw;
    line-height: auto;
    letter-spacing: 10%;
    font-size: 1.7vw;
    word-wrap: break-word;
}

.nature-sec1-inner .text-nature {
    max-width: 43%;
}

.img1-nature , .img2-nature , .img3-nature , .img4-nature {
    position: absolute;
}

.nature-sec2-inner .img1-nature {
    right: 0;
    top: 14%;
    z-index: -2;
    max-width: 42%;
}

.nature-sec2-inner .img2-nature {
    right: 22%;
    top: 52%;
    z-index: -1;
    max-width: 32%;
}

.flex-box-nature , .flex-sec4-nature {
    display: flex;
}

.text-main-nature {
    position: relative;
}


.text-main-nature :nth-child(3) {
    width: 40%;
}

.nature-sec2 .sec2-p {
    width: 80%;
}

.nature-sec2-inner .line-nature {
    margin-top: 5.82vw;
}






.nature-sec3-inner .img1-nature {
    right: 0;
    top: 16%;
    max-width: 40%;
}

.nature-sec3-inner .img2-nature {
    right: 23%;
    top: 31
    .5%;
    max-width: 28%;
}

.nature-sec3-inner .img3-nature {
    left: 0;
    top: 0;
    max-width: 29.8%;
}

.nature-sec3-inner .img4-nature {
    left: 22%;
    top: 6.4vw;
    max-width: 22.4%;
}

.flex-p-nature {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 1.07vw;
    overflow: hidden;
}

.p-sec3-nature {
    width: 42%;
    margin-right: 3%;
    margin-top: 4%;
}

.text1-sec3And4-nature , .text1-sec3And4-nature-mobile {
    max-width: 62%;
    background-color: rgb(188, 202, 196);
    border-radius: 0.71vw;
    padding: 0.568vw 1.42vw;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: inline-block;
    margin: 0 1.78vw;
    margin-top: 3.4vw;
}

.nature-sec3 .text2-sec3-nature {
    max-width: 47%;
    margin: 0 1.78vw;
    margin-top: 2.26vw;
}

.p-sec3-nature :nth-child(1) :nth-child(2){
    font-size: 1.7vw;
}

.p-sec3-nature:nth-child(1) {
    margin-top: 2.5%;
}

.text2-sec3-nature div , .flex-bottom-text-sec3-nature .text-nature :first-child {
    font-weight: 500;
}



.p-sec3-nature .text-nature:nth-child(2) {
    margin-top: 5.8vw;
}

.nature-sec3 .line-nature {
    margin-top: 5.82vw;
}

.p-sec3-nature .text-nature {
    margin: 0 0;
}



.img3-4-sec3-nature {
    position: relative;
}

.flex-bottom-text-sec3-nature {
    display: flex;
    justify-content: space-between;
    margin-top: 1.28vw;
}




.nature-sec4-inner .img1-nature {
    position: static;
    max-width: 34.5%;
}

.flex-sec4-nature {
    margin-top: 2.2vw;
}

.flex-sec4-nature .text-nature:nth-child(3) {
    margin-bottom: 0;
}

.nature-sec4-inner .text1-sec3And4-nature {
    max-width: 100%;
    margin-bottom: 4.4vw;
}

.nature-sec4-inner .text-nature {
    margin-top: 0;
}

.nature-sec4-inner .text-nature:nth-child(2), .nature-sec4-inner .text-nature:nth-child(3) {
    width: 85%;
}

.nature-sec4-inner .line-nature {
    margin-top: 5.82vw;
    margin-bottom: 5.61vw;
}

.nature-sec1-inner div:nth-child(3) img:nth-child(2) ,  .img3-4-sec3-nature-mobile , .text1-sec3And4-nature-mobile, .img-mobile, .text-mobile {
    display: none;
}

.unifying-nature {
    display: flex;
}

/* .nature-sec2-inner .box-hide {
    padding-right: 40%;
    padding-bottom: 38%;
} */

.text-nature-outer {
    max-width: 60%;
}

.text-nature-outer :nth-child(2) {
    max-width: 65%;
    margin: 7% 2% 3% 3%;
}

.text-nature-outer .text-nature:first-child {
    margin: 6% 2% 0 3%;
    max-width: 88%;
}



@media (min-width: 1840px) {
    .nature-sec3-inner .img2-nature {
        top: 30.5%;
    }
}


@media (max-width: 1520px) {
    .nature-sec3-inner .img2-nature {
        top: 30%;
    }
}


@media (max-width: 480px) {

    .img-mobile, .text-mobile {
        display: block;
    }

    .nature-sec1-inner div:nth-child(3) {
        margin-top: 15%;
    }

    .nature-sec1-inner div:nth-child(3) img:nth-child(2) , .text1-sec3And4-nature-mobile {
        display: block;
    }

    .nature-sec1-inner div:nth-child(3) img:nth-child(1) ,  .img3-4-sec3-nature , .block-hide, .box-hide, .text-hide {
        display: none;
    }

    .title-nature {
        margin: 3.27vw 0.85vw 2.77vw 0.57vw;
        font-size: 7.5vw;
        display: block;
        margin: 0 0;
        font-weight: 400;
        width: 100vw;
        max-width: 92.81vw;
    }

    .text-nature {
        max-width: 100%;
        font-size: 3.5vw;
        margin: 12% auto 7% 2%;
    }

    .nature-sec1-inner .text-nature , .nature-sec2-inner .text-nature {
        width: 100%;
        max-width: 92%;
    }

    .nature-sec1 .title-nature , .nature-sec2 .title-nature , .nature-sec3 .title-nature , .nature-sec4 .title-nature  {
        margin: 11% 2% 8% 2%;
    }

    .nature-sec2 .img-mobile :nth-child(1) {
       width: 80%;
       margin-left: 16%;
    }

    .nature-sec2 .img-mobile :nth-child(2) {
        width: 57%;
        position: absolute;
        bottom: 5%;
        left: 0;
    }

    .nature-sec2 .sec2-p:nth-child(2) {
        margin-bottom: -2vw;
    }

    .nature-sec2-inner .line-nature {
        margin-top: 11.82vw;
    }

    /* .nature-sec2-inner .img1-nature {
        bottom: 0;
        top: auto;
        right: inherit;
    }

    .nature-sec2-inner .img2-nature {
        bottom: 0;
        top: auto;
    }

    .nature-sec2-inner .img1-nature {
        bottom: -149%;
        max-width: 80.5vw;
        right: 2.84vw;
    }

    .nature-sec2-inner .img2-nature {
        bottom: -145%;
        max-width: 56.9vw;
        right: 45%;
    } */

    .nature-sec2 .title-nature {
        margin-top: 8%;
    }

    .nature-sec2-inner .text-nature {
        max-width: 88%;
        margin-left: 3%;
    }

    .text-nature-outer {
        max-width: 100%;
    }

    .text-nature-outer .text-nature:first-child {
        max-width: 90%;
        margin-top: 0;
    }

    .text-nature-outer :nth-child(2) {
        margin-bottom: -4%;
        max-width: 88%;
    }

    .nature-sec3 .title-nature {
        width: auto;
        max-width: 95vw;
        margin: 2.2vw 0.85vw 0 1.57vw;
    }

    .nature-sec3 .line-nature {
        margin-top: 11.82vw;
    }

    .text1-sec3And4-nature {
        margin-bottom: 22.4vw;
    }

    /* .nature-sec3-inner .img1-nature {
        max-width: 75%;
        top: 28.5%;
    }

    .nature-sec3-inner .img2-nature {
        max-width: 53%;
        top: 35%;
        right: 50%;
    } */

    .nature-sec3 .text2-sec3-nature , .p-sec3-nature , .p-sec3-nature :nth-child(1) :nth-child(2) {
        width: 100%;
        max-width: 90%;
        font-size: 4vw;    
    }

    .img3-4-sec3-nature-mobile {
        display: block;
        position: relative;
        margin-top: 4vh;
        margin-bottom: 4.5vw;
    }

    .nature-sec3 .img-mobile {
        margin-bottom: 15vw;
    }

    .nature-sec3 .img-mobile :nth-child(1) {
        width: 80%;
        margin-left: 20%;
    }

    .nature-sec3 .img-mobile :nth-child(2) {
       width: 50%;
       position: absolute;
       bottom: -12%;
       left: 0;
    }

    .nature-sec3 .text2-sec3-nature {
        margin: 0 1.57vw;
    }

    .p-sec3-nature {
        margin-left: 1.57vw;
    }

    .p-sec3-nature:nth-child(1) {
        margin-top: 9.5%;
    }

    .p-sec3-nature .text-nature:nth-child(2) {
        margin-top: 9.5%;
    }

    .flex-sec4-nature {
        flex-direction: column;
    }

    .nature-sec4-inner .img1-nature   {
        width: 100vw;
        max-width: max-content;
        margin-bottom: 4vh;
    }

    .common-p-sec4-nature {
        max-width: inherit;
    }

    .nature-sec4 .title-nature {
        width: 100%;
        margin: 3.27vw 0.85vw 0 1.57vw;
    }

    .flex-sec4-nature {
        margin-top: 0;
    }

    .nature-sec4-inner .line-nature {
        margin-top: 13%;
        margin-bottom: 4%;
    }

    .img3-4-sec3-nature-mobile :nth-child(1) {
        width: 65%;
    }

    .img3-4-sec3-nature-mobile :nth-child(2) {
        width: 47%;
        position: absolute;
        bottom: 0;
        right: 5%;
    }

    .nature-sec4 .img-mobile {
        margin-bottom: 4vh;
    }

    body .text1-sec3And4-nature, body .text1-sec3And4-nature-mobile {
        border-radius: 5.71vw;
        padding: 3.568vw 3.42vw;
        max-width: 92vw;
        margin: 8% 2% 8% 2%;
    }

    body .text1-sec3And4-nature-mobile {
        max-width: 84%;
        margin: 10% 0;
        font-size: 3.5vw;
        padding: 2.2% 0.2% 1.2% 4.2%;
        margin-left: 1.57vw;
    }
    

    /* body .img1-nature-mobile {
        max-width: 77%;
        position: absolute;
        right: 8%;
        top: 0;
    }

    body .img2-nature-mobile {
        max-width: 52%;
        position: absolute;
        left: 0;  
        top: 43%;
    } */

    .img-mobile {
        position: relative;
    }

    /* .nature-sec3-inner .img-mobile {
        padding: 33%;
    } */

    .nature-sec3-inner .img1-nature-mobile {
        right: 0;
    }

}