/* ОБЩИЕ СТИЛИ */

section.block {
    width: 100%; 
    position: relative; 
    border: none !important;
}

.text-block {
    width: 100%; 
    padding: 4.86vw 3.47vw;
    position: absolute; 
    background: #445852; 
    display: inline-flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: flex-start; 
    gap: 0.69vw;
    color: white;
}


.block p {
    font-size: 1.66vw;
    font-family: 'Roboto'; 
    font-weight: 400; 
    letter-spacing: 0.029vw;
    word-wrap: break-word;
}

.block h2 {
    position: absolute;
    text-align: center;
    color: black;
    font-size: 2.5vw;
    font-family: Roboto;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.125vw;
    word-wrap: break-word;
}


.nations-list {
    width: 100vw;
    position: absolute;
    padding-left: 3.47vw;
    padding-right: 3.47vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3.47vw;
    display: inline-flex;
}

.nations-list .columns {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3.47vw;
    display: inline-flex;
}

.large-nations table td:nth-child(2) {
    width: 2.5%;
}

.large-nations table td:nth-child(3) {
    width: 2%;
}

.nations-list .columns p {
    width: 20.83vw;
}

.gallery {
    width: 100vw;
    height: 25.68vw;
    position: absolute;
    padding-left: 3.47vw;
    padding-right: 3.47vw;
    justify-content: space-between;
    align-items: flex-start;
    display: inline-flex;
}

.gallery img {
    width: 29.65vw;
    height: 25.68vw;
}


.line {
    width: 100%; 
    height: 0; 
    left: 0; 
    position: absolute; 
    border: 0.052vw solid black;
}

.small-nations h2 {
    text-transform: uppercase;
}


@media screen and (min-width: 320px) and (max-width: 568px) {
    .text-block {
        padding: 3.125vw 6.25vw;
    }

    .block p {
        font-size: 3.75vw;
        letter-spacing: 0.075vw;
    }
    
    .block h2 {
        font-size: 4.375vw;
        letter-spacing: 0.21875vw;
    }
    
    .nations-list {
        padding-left: 3.125vw;
        padding-right: 3.125vw;
        gap: 3.125vw;
    }
    
    .nations-list .columns {
        gap: 3.125vw;
        flex-wrap: wrap;
        align-self: stretch;
    }
    
    .nations-list .columns p {
        width: 43.75vw;
    }
    
    .gallery {
        height: 26.5625vw;
        padding-left: 3.125vw;
        padding-right: 3.125vw;
    }
    
    .gallery img {
        width: 29.6875vw;
        height: 26.5625vw;
    }

    .line {
        border: 0.3125vw solid black;
    }
}



/* БЛОК ИНТРО */

section.intro {
    height: 91.25vw;
    background: #BCCAC4;
}

.block h1 {
    width: 29.06vw;
    height: 2.76vw;
    left: 3.70vw;
    top: 6.56vw;
    position: absolute; 
    color: black; 
    font-size: 2.5vw;
    font-family: 'Roboto'; 
    font-weight: 400; 
    letter-spacing: 0.125vw;
    word-wrap: break-word;
    text-transform: uppercase;
}

.intro .line-1 {
    top: 11.04vw;
}

.intro .text-block {
    top: 55.56vw;
}

.intro .text-block p {
    width: 27.22vw;
}

.intro .image1, .image2, .image3 {
    position: absolute;
    border-radius: 9999px;
}

.intro .image1 {
    width: 60.90vw;
    height: 37.85vw;
    left: 1.18vw;
    top: 13.96vw;
}

.intro .image2 {
    width: 61.18vw;
    height: 40.10vw;
    left: 35.83vw;
    top: 47.64vw;
}

.intro .image3 {
    width: 32.65vw;
    height: 32.74vw;
    left: 65.10vw;
    top: 15.21vw;
}

@media screen and (min-width: 320px) and (max-width: 568px) {
    section.intro {
        height: 154.0625vw;
    }
    
    .block h1 {
        width: 43.75vw;
        left: 3.125vw;
        top: 6.25vw;
        font-size: 4.375vw;
        letter-spacing: 0.21875vw;
    }
    
    .intro .line-1 {
        top: 14.0625vw;
    }
    
    .intro .text-block {
        top: 103.125vw;
    }
    
    .intro .text-block p {
        width: 71.875vw;
    }
    
    .intro .image1 {
        width: 68.75vw;
        height: 42.8125vw;
        left: 3.125vw;
        top: 19.375vw;
    }
    
    .intro .image2 {
        width: 55.3125vw;
        height: 36.5625vw;
        left: 41.5625vw;
        top: 63.125vw;
    }
    
    .intro .image3 {
        width: 32.8125vw;
        height: 32.8125vw;
        left: 3.125vw;
        top: 65vw;
    }
}


/* БЛОК ХАРАКТЕРИСТИКИ НАРОДОВ */

section.characteristics {
    height: 55.7639vw;
}

.characteristics h2 {
    width: 80vw;
    left: 9vw;
    top: 6.9097vw;
}

.characteristics .list {
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    gap: 3.4722vw;
    display: inline-flex;
    color: black;
}

.characteristics .list .list-number {
    opacity: 0.10;
    font-size: 6.67vw;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: 0.133vw;
    word-wrap: break-word;
}

.characteristics .list-1 {
    width: 70.4167vw;
    left: 3.6806vw;
    top: 18.5632vw;
}

.characteristics .list-2 {
    width: 82.9167vw;
    left: 3.6806vw;
    top: 28.6274vw;
}

.characteristics .list-3 {
    width: 48.3333vw;
    left: 3.6806vw;
    top: 39.3403vw;
}

.characteristics .list-4 {
    width: 46.3889vw;
    left: 52.1528vw;
    top: 39.3403vw;
}

.characteristics .line-1 {
    top: 15vw;
}

.characteristics .line-2 {
    top: 51.8194vw;
}

@media screen and (min-width: 320px) and (max-width: 568px) {
    section.characteristics {
        height: 174.375vw;
    }
    
    .characteristics h2 {
        width: 90.3125vw;
        left: 5vw;
        top: 6.25vw;
    }
    
    .characteristics .list {
        width: 93.75vw; 
        flex-direction: column; 
        justify-content: flex-start; 
        align-items: flex-start; 
        gap: 3.125vw;
        display: inline-flex;
    }
    
    .characteristics .list .list-number {
        font-size: 6.25vw;
        letter-spacing: 0.125vw;
    }
    
    .characteristics .list-1 {
        left: 3.125vw;
        top: 28.4375vw;
    }
    
    .characteristics .list-2 {
        left: 3.125vw;
        top: 66.25vw;
    }
    
    .characteristics .list-3 {
        left: 3.125vw;
        top: 111.25vw;
    }
    
    .characteristics .list-4 {
        left: 3.125vw;
        top: 140vw;
    }
    
    .characteristics .line-1 {
        top: 21.25vw;
    }
    
    .characteristics .line-2 {
        top: 170.9375vw;
    }
}


/* БЛОК МАЛОЧИСЛЕННЫЕ НАРОДЫ */

section.small-nations {
    height: 158.47vw;
}

.small-nations h2 {
    width: 70.31vw;
    left: 14.26vw;
    top: 2.19vw;
}

.small-nations .content {
    width: 100vw;
    height: 58.75vw;
    top: 9.58vw;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
}

.small-nations .content .left {
    height: 58.75vw;
    padding-left: 3.47vw;
    padding-right: 3.47vw;
    background: #BCCAC4;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3.47vw;
    display: inline-flex;
}

.small-nations .content .left .main {
    width: 47.57vw;
    padding-left: 2.43vw;
    padding-right: 2.43vw;
    padding-top: 2.15vw;
    padding-bottom: 2.15vw;
    border-radius: 1.04vw;
    border: 0.05vw black solid;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.69vw;
    display: inline-flex;
}

.small-nations .content .left .list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3.13vw;
    display: flex;
}

.small-nations .content .left .list .title {
    width: 35.24vw;
}

.small-nations .content .left .list .items {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.81vw;
    display: flex;
}

.small-nations .content .right {
    height: 100%;
}

.small-nations .nations-list {
    top: 74.24vw;
}

.small-nations .note {
    width: 76.39vw;
    height: 10.56vw;
    top: 112.08vw;
    position: absolute;
    padding-left: 3.47vw;
    padding-right: 3.47vw;
    padding-top: 3.33vw;
    padding-bottom: 3.33vw;
    background: #445852;
    justify-content: center;
    align-items: center;
    gap: 0.69vw;
    display: inline-flex;
    color: white;
}

.small-nations .gallery {
    top: 127.92vw;
}

.small-nations .line-1 {
    top: 158vw;
}


@media screen and (min-width: 320px) and (max-width: 568px) {
    section.small-nations {
        height: 403.4375vw;
    }
    
    .small-nations h2 {
        width: 70.3125vw;
        left: 14.0625vw;
        top: 5.3125vw;
    }
    
    .small-nations .content {
        top: 22.1875vw;
        width: 100vw; 
        height: 169.428125vw; 
        flex-direction: column; 
        justify-content: flex-start; 
        align-items: flex-start; 
        display: inline-flex;
    }
    
    .small-nations .content .left {
        align-self: stretch; 
        height: 110.625vw; 
        padding-left: 3.125vw; 
        padding-right: 3.125vw; 
        background: #BCCAC4; 
        flex-direction: column; 
        justify-content: center; 
        align-items: flex-start; 
        gap: 3.125vw; 
        display: flex;
    }
    
    .small-nations .content .left .main {
        width: auto;
        align-self: stretch; 
        padding: 2.1875vw;
        border-radius: 1.25vw; 
        overflow: hidden; 
        border: 0.0625vw black solid; 
    }
    
    .small-nations .content .left .list {
        align-self: stretch; 
        height: 61.04125vw;  
        gap: 3.125vw;
    }
    
    .small-nations .content .left .list .title {
        align-self: stretch;
        width: 81.25vw;
    }
    
    .small-nations .content .left .list .items {
        align-self: stretch;
        gap: 3.125vw;
    }
    
    .small-nations .content .right {
        align-self: stretch; 
        height: 58.803125vw;
    }
    
    .small-nations .nations-list {
        top: 199.0625vw;
    }
    
    .small-nations .note {
        top: 331.25vw;
        width: 88.125vw; 
        height: 30vw; 
        padding-left: 3.125vw; 
        padding-right: 3.125vw; 
        padding-top: 6.25vw; 
        padding-bottom: 6.25vw; 
        justify-content: center; 
        align-items: center; 
        display: inline-flex;
    }
    
    .small-nations .gallery {
        top: 371.875vw;
    }
    
    .small-nations .line-1 {
        top: 403.125vw;
    }
}


/* БЛОК КРУПНЫЕ НАРОДЫ */

section.large-nations {
    height: 95vw;
}

.large-nations h2 {
    width: 54.79vw;
    left: 21.73vw;
    top: 4.03vw;
}

.large-nations .text-block {
    top: 9.58vw;
}

.large-nations .text-block p {
    width: 52.08vw;
}

.large-nations .nations-list {
    top: 28vw;
}

.large-nations .gallery {
    top: 61.6vw;
}

.large-nations .line-1 {
    top: 94.9vw;
}

@media screen and (min-width: 320px) and (max-width: 568px) {
    section.large-nations {
        height: 216.5625vw;
    }
    
    .large-nations h2 {
        width: 54.6875vw;
        left: 21.875vw;
        top: 5.3125vw;
    }
    
    .large-nations .text-block {
        top: 23.4375vw;
    }
    
    .large-nations .text-block p {
        width: 78.125vw;
    }
    
    .large-nations .nations-list {
        top: 59.375vw;
    }
    
    .large-nations .gallery {
        top: 178.125vw;
    }
    
    .large-nations .line-1 {
        top: 211.875vw;
    }
}

/* БЛОК ДЕЯТЕЛЬНОСТЬ */

section.activity {
    height: 147.57vw;
}

.activity h2 {
    width: 91.25vw;
    left: 3.13vw;
    top: 5.21vw;
}

.activity .text-block {
    top: 15.56vw;
}

.activity .text-block p {
    width: 80vw;
}

.activity .activity-list {
    padding-left: 3.47vw;
    padding-right: 3.47vw;
    left: 0vw;
    top: 43vw;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.39vw;
    display: inline-flex;
}

.activity .activity-list .list_unit {
    width: 97.22vw;
    height: 23.61vw;
    position: relative;
}

.activity .activity-list .list_unit img {
    width: 32.38vw;
    height: 23.61vw;
    left: 60.63vw;
    top: 0vw;
    position: absolute;
}

.activity .activity-list .list_unit div {
    width: 64.12vw;
    height: 20.57vw;
    padding-left: 1.39vw;
    padding-right: 1.39vw;
    left: 0vw;
    top: 1.53vw;
    position: absolute;
    border: 0.05vw black solid;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.69vw;
    display: inline-flex;
}

.activity .activity-list .list_unit p {
    width: 55.56vw;
}

@media screen and (min-width: 320px) and (max-width: 568px) {
    section.activity {
        height: 371.875vw;
    }
    
    .activity h2 {
        width: 81.25vw;
        left: 9.375vw;
        top: 3.125vw;
    }
    
    .activity .text-block {
        top: 25vw;
    }
    
    .activity .text-block p {
        width: 81.25vw;
    }
    
    .activity .activity-list {
        padding-left: 3.125vw;
        padding-right: 3.125vw;
        top: 103.125vw;
        gap: 6.25vw;
    }
    
    .activity .activity-list .list_unit {
        width: 93.75vw;
        height: fit-content;
        position: relative;
    }
    
    .activity .activity-list .list_unit img {
        width: 31.25vw;
        height: 23.4375vw;
        left: 60.9375vw;
    }
    
    .activity .activity-list .list_unit div {
        width: 66.5625vw; 
        height: max-content;
        padding: 3.125vw; 
        margin-top: 1.5625vw;
        position: relative; 
        border: 0.0625vw black solid; 
        flex-direction: column; 
        justify-content: flex-start; 
        align-items: flex-start; 
        gap: 0.69375vw; 
        display: inline-flex;
    }
    
    .activity .activity-list .list_unit p {
        width: 55.625vw;
    }
}
