body {
    overflow-x: hidden;
}
.problems-intro {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

section:nth-child(n+3) {
    border-top: 1px solid #000000;
}

.problems-intro img{
    width: 100%;
}

.title-header{
    margin-top: 5%;
    margin-left: 5%;
}

.title-intro {
    margin-top: 4%;
    align-self: center;
}

.title-problems {
    line-height: 116.7%;
    letter-spacing: 0.1em;
    font-size: 2vw;
    text-transform: uppercase;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.line-problems img {
    width: 100%
}

.climate-container, .language-container {
    display: flex;
    margin: 5% 3% 8% 3%;
}

.box {
    background: #BCCAC4;
    border-radius: 20px;
}

.problems-box-1_1 {
    flex: 1;
    padding: 4% 4%;
    margin-right: 8%;
}

.problems-box-1_2 {
    flex: 1;
    position: relative;
    height: fit-content;
}

.problems-box-2_1 {
    position: relative;
}

.problems-box-2_2 {
    padding: 4% 1% 3% 1%;
    margin-right: 3%;
    margin-left: 3%;
}

.problems-box-1_1 h2 , .problems-box-2_2 h2{
    margin-bottom: 4%;
}

.text-problems {
    font-weight: 400;
    font-size: 1.45vw;
    line-height: 116.67%;
    text-align: justify;
    letter-spacing: 0.1em;
}

.icefall {
    position: absolute;
    right: 0;
    top: 88%;
}

.trash {
    position: absolute;
    top: 65%;
    left: -12%;
}


.shamans {
    position: absolute;
    left: 4%;
    top: 70%;
}

/* Вывод */
.conclusion h2 {
    text-transform: none;
    margin-bottom: 3%;
}

.conclusion {
    box-sizing: border-box;
    border: 1px solid #000000;
    padding: 2% 2%;
    margin: 2% 4% 3%;
}

.sakhalin-container-1 {
    display: flex;
    margin-left: 10%;
}

.problems-box-3_1 {
    margin-right: 2%;
    height: fit-content;
}

.problems-box-3_2 {
    margin-top: 3%;
}

.sakhalin-container-2 {
    display: flex;
}

.problems-box-3_3 {
    margin-right: 4%;
    margin-left: 4%;
    margin-top: 4%;
}

.problems-box-3_4 {
    position: relative;
    margin-top: -18%;
}

.sakhalin-logo {
    position: absolute;
    top: 5%;
    left: 0;
}

.fpic-container-1 {
    display: flex;
    margin-top: 2%;
}

.fpic-container-2 {
    display: flex;
    margin-top: 2%;
}

.problems-box-4_2 {
    margin-left: 3%;
    margin-right: 4%;
    margin-top: 1%;
}

.fpic-cases-mansi h3, .fpic-cases-shorchev h3{
    margin-bottom: 1%;
    margin-top: 1%;
    font-weight: 600;
    font-size: 1.45vw;
}

.problems-box-4_3 {
    margin-left: 3%;
    margin-right: 2%;
    margin-bottom: 3%;
}

.problems-box-4_4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 480px) {


    .text-problems {
        font-size: 2.08vw;
    }

    .title-problems {
        font-size: 4.17vw;
    }

    .title-intro {
        text-align: center;
    }

    .title-header {
        font-size: 28px;
    }

    .climate-container, .language-container {
        margin-top: 0;
    }

    .language-container {
        margin-bottom: 92px;
    }

    .climate-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
    }

    .problems-box-1_1 , .problems-box-2_2 {
        margin: 28px;
        padding: 12px;
    }

    .problems-box-1_2 {
        margin-bottom: 96px;
    }

    .power_germany {
        width: 270px;
        height: 180px;
    }

    .icefall {
        width: 127px;
        height: 77px;
    }

    .trash {
        width: 130px;
        height: 94px;
    }

    .language-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .dance {
        width: 271px;
        height: 187px;
    }

    .shamans {
        width: 147px;
        height: 89px;
    }

    .sakhalin-container-1 {
        margin-left: 0;
        flex-direction: column-reverse;
    }

    .problems-box-3_1 {
        margin-right: 0;
    }

    .problems-box-3_2 {
        margin: 28px;
    }

    .sealand {
        width: 100%;
        height: 398px;
    }

    .sakhalin-container-2 {
        flex-direction: column;
    }

    .sakhalin {
        width: 100%;
        height: 493px;
    }

    .sakhalin-logo {
        width: 87px;
        height: 80px;
    }


    .problems-box-3_3 {
        margin-top: 28px;
        margin-bottom: 28px;
    }

    .problems-box-3_4 {
        margin-top: 0;
    }

    .fpic-container-1 {
        flex-direction: column-reverse;
    }

    .problems-box-4_2 {
        margin-bottom: 12px;
    }

    .river {
        width: 272px;
        height: 163px;
    }

    .fpic-cases-description > p:nth-child(2) {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .fpic-cases-mansi h3, .fpic-cases-shorchev h3{
        font-size: 10px;
        margin-bottom: 20px;
    }

    .fpic-container-2 {
        flex-direction: column;
        margin-bottom: 12px;
    }

    .mountains {
        width: 270px;
        height: 163px;
        align-self: end;
    }

}







