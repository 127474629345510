/*                                                Цитата 2                                                  */

.content4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quotation {
    max-width: 100%;
    position: relative;
}

.decoration1 , .decoration2 {
    max-height: 50%;
    position: relative;
}

.decoration1 img {
    position: absolute;
    left: 0.2%;
    top: 1vw;
}

.decoration2 img {
    position: absolute;
    right: 0.2%;
    bottom: 1vw;
}

.line-box {
    width: 100%;
}

.line-box img {
    height: 0.2vw;
    width: inherit;
}

.decoration1 .line-box {
    position: absolute;
    top: -16%;
}

.decoration2 .line-box {
    position: absolute;
    bottom: -16%;
}

.text-box4 {
    position: absolute;
    width: 97%;
    max-height: 15vh;
    top: 37%;
    left: 2%;
    font-size: 1.4vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text-box4:nth-child(1) p, .text-box4:nth-child(2) p {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 5%;
    font-weight: 400;
    font-size: 1.5625vw;
    line-height: 116.7%;
}

.text-box-inner {
    display: inline-flex;
    justify-content: center;
    max-height: 3vw;
}

.text-box-outer2 {
    display: inline-flex;
    justify-content: center;
    width: 98%;
    max-height: 3vw;
    margin-left: 1%;
}

.author2 {
    margin-top: 16%;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 5%;
    line-height: 116.7%;
}

.content4 .text-box-outer-mobile {
    display: none;
}











@media (max-width: 480px) {

    .text-box4 {
        display: flex;
    }

.content4 .text-box4 .text-box-outer2 , .content4 .text-box4 .text-box-outer {
    display: none;
}

.content4 .text-box4 .text-box-outer-mobile {
    display: block;
    font-size: 3.7vw;
}

.content4 .text-box4 .text-box-outer-mobile-1 , .content4 .text-box4 .text-box-outer-mobile-2 {
    display: block;
    letter-spacing: 0.1em;
    text-align: center;
}

.content4 .text-box4 .text-box-outer-mobile-1 {
    margin-top: -8%;
}

.content4 .text-box4 .text-box-outer-mobile-1 :nth-child(1) {
    margin-bottom: 3%;
}

.content4 .text-box-outer-mobile-2 p {
    margin-top: 5%;
    font-weight: 800;
}

}