.centerBlock {
    /* word-wrap: break-word; */
    margin-top: 30px;
    word-break: normal;
}

.AboutUs {
    letter-spacing: 10%;
    max-width: 80%;
    margin: 0 auto;
}


/*                                                Subsection1                                                */

.AboutUs h1 {
    font-size: 3.125vw;
    font-weight: 400;
    text-transform: uppercase;
}

.subsection1 :nth-child(2) {
    margin-top: 30px;
    font-size: 0.99vw;
    max-width: 92%;
}




/*                                                Subsection2                                                */


.subsection2 {
    display: flex;
    margin-top: 50px;
}

.subsec2-img {
    margin-right: 4%;
}

.subsec2-img img {
    max-width: 31vw;
}

.txt1 {
    font-size: 1.705vw;
}

.team {
    margin-top: 5%;
    font-weight: 700;
    font-size: 1.35vw;
}

.txt1-mobile {
    display: none;
}


/*                                               Subsection3                                                  */


.subsection3 {
    margin-top: 120px;
}

.header2 {
    text-transform: uppercase;
    font-size: 3.125vw;
    margin-bottom: 26px;
}

.line-decoration {
    display: flex;
    overflow: hidden;
}

.line-decoration :nth-child(2) {
    max-width: 450px;
}

.subtitle {
    margin: 52px auto;
    display: flex;
    justify-content: center;
}

.subtitle div p{
    font-size: 2.27vw;
    color: rgb(29, 54, 48);
}

.subsection3 :nth-child(4) {
    font-size: 1.705vw;
    max-width: 100%;
}


/*                                                 Subsection4                                                    */


.subsection4 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80vw;
    /* min-height: 58.4vh; */
    max-height: 66.7vh;
    border-radius: 5.8vw;
    border: rgb(110, 128, 122) solid 0.7vw;
    margin-bottom: 170px;
    margin-top: 170px;
    padding-bottom: 5%;
}

.innerCenterBlock {
    display: flex;
    justify-content: space-between;
    column-gap: 16.3vw;
    min-height: 34.9vh;
    /* border: violet solid; */
    /* padding-top: 20px; */
}

.faq-first {
    font-size: 4.5vw;
    text-transform: uppercase;
    margin-left: -2.1vw;
    letter-spacing: 0.15em;
    /* border: red solid; */
}

.faq-first h4 {
    margin-top: 30%;
}

.faq-second {
    margin-top: 7.6%;
    font-size: 0.99vw;
    font-weight: 700;
    /* border: blue solid; */
}

.faq-second p > .p-decoration {
    font-size: 0.99vw;
    font-weight: 900;
}

.subsection4 img {
    max-width: 38.6vw;
}

.options {
    margin: 1.8vh auto;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    cursor: pointer;
}

.plus {
    position: relative;
    /* margin-top: -1px; */
    display: flex;
    justify-content: center;
    width: 1.42vw;
    height: 3vh;
}


.vertical-line1, .vertical-line2, .vertical-line3 {
    background-color: black;
    width: 11%;
    height: 1.2vw;
    position: absolute;
}

.horizontal-line {
    background-color: black;
    width: 11%;
    height: 1.15vw;
    transform: rotate(90deg);
    position: absolute;
}

.common-block {
    max-width: 39.1vw;
}

.return {
    /* position: absolute; */
    max-width: 37.7vw;
    letter-spacing: 0.15em;
    font-weight: 400;
    height: 35px;
    margin-bottom: 22px;
    font-size: 0.85vw;
}



@media (max-width: 1600px) {
    .subsection4 {
        padding-bottom: 3%;
    }
}

@media (min-width: 1441px) {
    .subsection1 :nth-child(2) {
        max-width: 58%;
    }
}

@media (max-width: 1110px) {
    /* .subsection4 {
        padding-bottom: 0;
    } */

    .innerCenterBlock {
        min-height: 30.9vh;
    }
}

@media (max-width: 787px) {
    .vertical-line1, .vertical-line2, .vertical-line3 {
        width: 6%;
        height: 55%;
    }

    .horizontal-line {
        width: 6%;
        height: 55%;
    }

    .plus {
        width: 3vw;
        height: 3vw;
    }
}

@media (max-width: 800px) {
    .innerCenterBlock {
        min-height: 26.9vw;
    }

    .subsection4 {
        padding-bottom: 7%;
    }
}


@media (max-width: 480px) {

    .txt1 {
        display: none;
    }

    .txt1-mobile {
        display: block;
    }

    .subsection1 {
        /* max-width: 25.7vw; */
        margin: 17% auto;
    }

    .subsection1 :nth-child(2) {
        max-width: 100%;
        font-size: 3.3vw;
        margin-top: 8%;
    }

    .subsection2 {
        flex-direction: column;
        justify-content: center;
        margin-top: 22%;
    }

    .subsection3 {
        margin-top: 33%;
    }

    .subsection3 :nth-child(4) {
        font-size: 3.6vw;
    }

    .line-decoration {
        /* max-width: 23.8vw; */
        overflow: hidden;
    }

    .subtitle {
        margin: 15% 0;
        display: block;
    }

    .subsection4 {
        /* max-width: 23.8vw; */
        border: rgb(110, 128, 122) solid 2.92px;
        border-radius: 23px;
        margin: 26% auto;
        min-height: 18.11vw;
        padding-bottom: 8%;
    }

    .innerCenterBlock {
        flex-direction: column;
        column-gap: auto;
        min-height: 14.9vw;
        width: 90%;
    }

    .common-block {
        /* max-width: 19.2vw; */
    }

    .AboutUs h1 , .header2 {
        font-size: 6.99vw;
        letter-spacing: 0.135em;
        line-height: 155%;
    }

    .txt1-mobile {
        font-size: 3.3vw;
    }

    .subsection2 div {
        margin: 0 0;
    }

    .subsection2 .subsec2-img {
        margin-bottom: 23%;
    }

    .subsec2-img img {
        max-width: 80vw;
    }

    .subtitle div p {
        font-size: 4.136vw;
    }

    .header2 {
        /* max-width: 21.3vw; */
        margin: 0 auto;
        margin-bottom: 8%;
    }

    .subsection3 .line-decoration img {
        /* max-width: 23.9vw; */
    }

    .subsection3 .line-decoration {
        margin: 0 auto;
    }
    
    /* .subsection2 :nth-child(2) :nth-child(2) {
        max-width: 336px;
    } */

    .subsection4 img {
        max-width: fit-content;
    }

    .line-sub4 {
        overflow: hidden;
    }

    .options {
        font-size: 2vw;
        margin-bottom: 4%;
    }

    .common-block {
        max-width: 67.5vw;
    }

    .faq-first {
        margin: 0;
        font-size: 8.27vw; 
    }

    .faq-first h4 {
        margin-top: 6%;
    }

    .return {
        font-size: 2vw;
        max-width: 100%;
        margin-bottom: auto;
        margin-top: 5%;
    }

    .AboutUs .team {
        font-size: 2.85vw;
        margin-top: 5%;
        font-weight: 700;
    }
}