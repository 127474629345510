.sec2-main {
    margin: 0 auto;
}

.sec2-content {
    background-color: rgb(190, 202, 196);
    margin-top: 4.2%;
    padding-bottom: 3%;
    padding-top: 1.5%;
}

.sec2-main p, .box-sec2_1 h1 {
    color: rgb(247, 244, 238);
    font-family: 'Roboto';
    line-height: auto;
    margin: auto;
    letter-spacing: 10%;
    margin-bottom: 1.2%;
}

.sec2-main p {
    color: black;
    font-size: 1.7vw;
}

.box-sec2_2 p {
    margin-bottom: 1%;
}

.sec2-main h1 {
    font-size: 6.81vw;
    font-weight: 500;
    text-transform: uppercase;
}

.box-sec2_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    margin-bottom: 1.5%;
}

.box-sec2_2 :last-child , .box-sec2_1 div :last-child, .text2_1 {
   text-align: center;
}

.box-sec2_2 div {
    margin: 0 auto;
}


.sec2-mobile {
    display: none;
}






/*                                                                     Section2 Mobile                                                          */

@media (max-width: 480px) {

    .sec2-mobile {
        display: block;
        text-align: center;
    }

    .sec2-content {
        padding: 14% 0;
    }

    .sec2-mobile div {
        width: 90vw;
        margin: 0 auto;
        font-weight: 400;
        letter-spacing: 0.1em;
    }

    .sec2-main p {
        color: black;
        font-size: 3.99vw;
        font-weight: 700;
    }
    
    .box-sec2_2 p {
        margin-bottom: 0px;
    }
    
    .sec2-main h1 {
        font-size: 7.99vw;
        font-weight: 500;
        text-transform: uppercase;
    }

    .box-sec2_1 {
        margin-bottom: 5.5%;
        margin-top: -5.5%;
    }
    
}
