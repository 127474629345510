/*                                                            Quiz-title                                                           */


.slider-box h2 {
  text-transform: uppercase;
  font-size: 1.85vw;
  letter-spacing: 0.15em;
  font-weight: 500;
}

.test_your_knowledge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
  margin-left: 2.5%;
}



/*                                                             Slider-section                                                */


/* button {
  background-color: green;
} */

.slider {
  display: flex;
  max-width: 100%;
}

.slider-box {
  margin: auto;
  max-width: 77%;
  margin-top: 18%;
}

/*                                          next-arrow                                            */

.arrow2-box {
  margin-top: 22%;
  margin-left: 0px;
  /* увеличивает отступ за счет компонента Slider, т.е. уменьшает его на данное кол-во px */
}

.arrow-2 {
  cursor: pointer;
  position: relative;
  max-width: 5.7vw;
  height: 5vh;
  margin: 2vw;
}
.arrow-2 div {
  position: relative;
  background-color: black;
  left:0;
  display: block;
  top: 5%;
  width: 6.2vw;
  height: 0.5vw;
}
.arrow-2 div::after {
  content: '';
  position: absolute;
  background-color: black;
  transform: rotate(45deg);
  width: 17%;
  height: 0.4vw;
  top: -57%;
  right: -3%;
}
.arrow-2 div::before {
  content: '';
  position: absolute;
  background-color: black;
  transform: rotate(-45deg);
  width: 21%;
  height: 0.4vw;
  top: 85%;
  right: -5%;
}
.arrow-2:hover {
  animation: arrow-1 1s linear infinite;
}
@keyframes arrow-2 {
  0% {
      left:0;
  }
  50% {
      left:10px;
  }
  100% {
      left:0;
  }
}


/*                                                                prev-arrow                                          */

.arrow1-box {
  margin-top: 22%;
  margin-right: 0.9%;
  /* увеличивает отступ за счет компонента Slider, т.е. уменьшает его на данное кол-во px */
}

.arrow-1 {
  cursor: pointer;
  position: relative;
  max-width: 5.7vw;
  height: 5vh;
  margin: 2vw;
}
.arrow-1 div {
  position: relative;
  background-color: black;
  left:0;
  display: block;
  top: 5%;
  width: 6.2vw;
  height: 0.5vw;
}
.arrow-1 div::after {
  content: '';
  position: absolute;
  background-color: black;
  transform: rotate(135deg);
  width: 20%;
  height: 0.4vw;
  top: -42%;
  right: 86%;
}
.arrow-1 div::before {
  content: '';
  position: absolute;
  background-color: black;
  transform: rotate(-135deg);
  width: 20%;
  height: 0.4vw;
  top: 82%;
  right: 86%;
}
.arrow-1:hover {
  animation: arrow-2 1s linear infinite;
}
@keyframes arrow-1 {
  0% {
      right:0;
  }
  50% {
      right:10px;
  }
  100% {
      right:0;
  }
}



/*                                                                  Quiz-inner                                                    */


.victorina {
  padding: 5% 2.6%;
  max-width: 100%;
  background-color: rgb(247,244,238);
  margin: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 0.99vw !important;
}

.question-container, .question-text {
  background-color: rgb(110, 128, 122);
  border-radius: 5vw;
  margin-bottom: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
  color: white;
  max-width: 53vw;
  padding: 12% 1% 11% 1%;
}

.question-text p {
  max-width: 90%;
  letter-spacing: 0.15em;
}

.question-section :nth-child(2) {
  margin-bottom: 8%;
}

.answers-block {
  display: flex;
}

.answers-block div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.answers-block div p {
  letter-spacing: 0.15em;
  max-width: 100%;
  margin: 0 4%;
}

.answers-block div, .answer{
  background-color: rgb(225,223,218);
  height: 6.7vh;
  border-radius: 1.5vw;
  margin-bottom: auto;
}

.answer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 52.6vw;
}

.score-section img {
  max-width: 24.2vw;
}








/*                                                                  Library Quiz                                                */

  .score-section {
    align-items: center;
    color: black;
  }


  .score-section:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    padding-bottom: 20%;
  }

  .score-stats {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

  .score-section:first-child div:nth-child(2) {
    margin-bottom: 6%;
  }

  .score-section:first-child div:nth-child(3) {
    font-size: 1.7vw;
  }






  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin-bottom: 20px;
    color: black;
  }
  
  .question-count span {
    font-size: 2vw;
    color: black;
  }
  
  .question-text {
    margin-bottom: 12px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 0.5%;
  }
  
  .answer-section button {
    border-radius: 1.5vw;
    margin-bottom: 5.5%;
    background-color: rgb(225,223,218);
    font-size: 1.136vw;
    color: black;
    display: flex;
    padding: 7%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  
  button:hover {
    background-color: #555e7d;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }


  
  

/*                                                     Mobile SliderAndQuiz section                                                            */




@media (max-width: 480px) {
  /*                                                            Quiz-title                                                           */


.slider-box h2 {
  text-transform: uppercase;
  font-size: 4.7vw;
  letter-spacing: 0.15em;
  font-weight: 400;
}

.test_your_knowledge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13%;
  margin-left: -15%;
  width: 131%;
}



/*                                                             Slider-section                                                */


button {
  background-color: green;
}

.slider {
  display: flex;
  width: 100vw;
  justify-content: center;
  height: auto;
  max-height: inherit;
}

.slider-box {
  width: 100vw;
  max-width: 83%;
  margin: auto;
}

.quiz-container {
  padding-bottom: 3%;
}

/*                                          next-arrow                                            */

.arrow2-box {
  margin-top: 22%;
  width: 5%;
  margin-left: 0px;
  /* увеличивает отступ за счет компонента Slider, т.е. уменьшает его на данное кол-во px */
}

.arrow-2 {
  cursor: pointer;
  position: relative;
  width: 113%;
  height: 37%;
}
.arrow-2 div {
  position: relative;
  top: 17%;
  height: 0.9vw;
  background-color: black;
  left:0;
  display: block;
}
.arrow-2 div::after {
  content: '';
  position: absolute;
  width: 2.5vw;
  height: 0.7vw;
  top: -57%;
  right: -16%;
  background-color: black;
  transform: rotate(45deg);
}
.arrow-2 div::before {
  content: '';
  position: absolute;
  width: 2.5vw;
  height: 0.7vw;
  top: 97%;
  right: -16%;
  background-color: black;
  transform: rotate(-45deg);
}

.arrow-2:hover {
  animation: arrow-1 0.5s linear;
}

@keyframes arrow-2 {
  0% {
      left:0;
  }
  50% {
      left:10px;
  }
  100% {
      left:0;
  }
}


/*                                                                prev-arrow                                          */

.arrow1-box {
  margin-top: 22%;
  margin-right: 0px;
  /* увеличивает отступ за счет компонента Slider, т.е. уменьшает его на данное кол-во px */
  width: 5%;
}

.arrow-1 {
  cursor: pointer;
  position: relative;
  width: 113%;
  height: 37%;
  margin-left: -107%;
}
.arrow-1 div {
  position: relative;
  top: 17%;
  height: 0.9vw;
  background-color: black;
  left:0;
  display: block;
}
.arrow-1 div::after {
  content: '';
  position: absolute;
  width: 2.5vw;
  height: 0.7vw;
  top: -63%;
  right: 76%;
  background-color: black;
  transform: rotate(135deg);
}
.arrow-1 div::before {
  content: '';
  position: absolute;
  width: 2.5vw;
  height: 0.7vw;
  top: 90%;
  right: 76%;
  background-color: black;
  transform: rotate(-135deg);
}

.arrow-1:hover {
  animation: arrow-2 0.5s linear;
}

@keyframes arrow-1 {
  0% {
      right:0;
  }
  50% {
      right:10px;
  }
  100% {
      right:0;
  }
}



/*                                                                  Quiz-inner                                                    */


.victorina {
  height: auto;
  background-color: rgb(247,244,238);
  margin: 0 auto;
  padding: 0 3%; 
  font-family: 'Roboto', sans-serif;
  font-size: 2.7vw !important;
}

.question-container, .question-text {
  background-color: rgb(110, 128, 122);
  border-radius: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
  color: white;
  padding: 0 50%;
  height: 56vw;
  position: relative;
}

.question-text p {
  width: auto;
  letter-spacing: 0.15em;
  text-align: center;
  position: absolute;
}

.question-section :nth-child(2) {
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}

.answers-block {
  display: flex;
}

.answers-block div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.answers-block div p {
  max-width: inherit;
  letter-spacing: 0.15em;
}

.answers-block div, .answer{
  height: auto;
  background-color: rgb(225,223,218);
}

.answers-block :last-child {
  margin-left: 0px;
  padding: 1% 1%;
  text-align: center;
}

.answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: inherit;
}

.score-section img {
  margin: 0 auto;
  max-width: 45vw;
}





/*                                                                  Library Quiz                                                */
  
  .score-section {
    align-items: center;
    color: black;
  }


  .score-section:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }
  

  .score-section:first-child div:nth-child(2) {
    margin-bottom: 4%;
    display: flex;
    justify-content: center;

  }

  .score-section:first-child div:nth-child(3) {
    font-size: 3.4vw;
    margin-left: 0px;
    text-align: center;
    width: 93%;
  }






  /* QUESTION/TIMER/LEFT SECTION */
  .question-section {
    width: 100%;
    position: relative;
  }
  
  .question-count {
    margin: 4% auto;
    width: fit-content;
  }
  
  .question-count span {
    font-size: 4.28vw;
  }
  
  .question-text {
    margin-bottom: 12px;
  }
  
  .timer-text {
    background: rgb(230, 153, 12);
    padding: 15px;
    margin-top: 20px;
    margin-right: 20px;
    border: 5px solid rgb(255, 189, 67);
    border-radius: 15px;
    text-align: center;
  }
  
  /* ANSWERS/RIGHT SECTION */
  .answer-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
  }
  
  .answer-section button {
    width: auto;
    background-color: rgb(225,223,218);
    margin-bottom: 2.7%;
    font-size: 2.7vw;
    font-weight: 700;
    color: black;
    display: flex;
    padding: 3%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .correct {
    background-color: #2f922f;
  }
  
  .incorrect {
    background-color: #ff3333;
  }
  
  button:hover {
    background-color: #555e7d;
  }
  
  button:focus {
    outline: none;
  }
  
  button svg {
    margin-right: 5px;
  }

}