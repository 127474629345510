body {
    font-family: 'Roboto',  'Barlow', sans-serif;
    letter-spacing: 0.1em;
    background-color: rgb(247, 244, 238);
    /* overflow-x: hidden; */
    
    /* работает в связке с min-width у .App */
    /* width: 100vw; */
}

header, main {
    display: block;
}


.App {
    /* margin: 0 auto; */
    position: relative;
    z-index: 1;
    /* min-width: fit-content; */
}


li :hover , a:hover, .row-eng div:hover , .text-box-footer div:hover {
    text-decoration: underline !important;
}


.logo-container img :hover, .row-eng div:hover , .text-box-footer div:hover{
    cursor: pointer !important;
}

a:hover {
    cursor: pointer;
    text-decoration: underline;
}



/* @media (max-width: 1440px) {

    .footer-img {
        column-gap: 30px;
    }

    .App {
        min-width: auto;
    }

    .landscape0 img {
        max-width: 98.8vw;
    }

} */




@media (max-width: 480px) {

    .App {
        overflow-x: hidden;
    }


/*                                                                     Header                                                          */


    header {
        height: auto;
    }

    .logo-container img {
        width: 13vw;
    }

    .logo-container {
        margin-top: 0.83%;
        margin-left: 5%;
        gap: 10vw;
    }
    

    /* очень важная настройка ниже не трогать */

    .container {
        display: flex;
        align-items: baseline;
        
    }

    .ru {
        font-size: 2vw;
        letter-spacing: 0.1em;
        font-weight: 500;
        margin-left: -0.6em;
    }

    .links-outer {
        display: none;
    }


    .sliding-menu {
        position: fixed;
        width: 82vw;
        right: 0px;
        height: 200%;
        /* background-color: wheat; */
        background-color: rgb(225, 223, 218);
        z-index: 1;
        animation: MenuAnimation 0.45s;
    }

    .mobile-nav {
        margin-top: 15%;
    }

    .sliding-menu ul {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 3vh;
    }

    .sliding-menu ul li {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .sliding-menu a{
        text-decoration: none;
        font-weight: 500;
        color: rgb(6, 20, 14);
        margin-left: 5%;
    }



    @keyframes MenuAnimation {
        from {width: 0px;}
        to { width: 82vw;}
    }

    @keyframes CloseAnimation {
        from {width: 82vw;}
        to { width: 0px;}
    }

    .close-menu {
        animation: CloseAnimation 0.45s;
    }

    .sliding-menu div {
        z-index: 2;
        display: block;
    }
















    /* .links-outer a {
        font-size: 20px;
        justify-content: start;
    }

    nav {
        position: fixed;
        top: 0; bottom: 0; left: 0; right: 0;
        animation: burgerAnimation 0.4s;
        z-index: 50;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: rgb(247, 244, 238);
        padding: 50px 40px;
        border: red solid;

    }

    nav ul {
        flex-direction: column;
        row-gap: 30px;
        width: 200px;
    }

    nav ul li {
       
    }

    @keyframes burgerAnimation {
        from { opacity: 0}
        to { opacity: 1}
    } */


    




/*                                                                     Main                                                          */


    main {
        min-width: 390px;
    }

    .landscape0 img {
        min-width: 390px;
    }



/*                                                                     Footer                                                          */



    .footer-box {
        /* height: 393px; */
        padding-top: 3.33vw;
        height: auto;
        display: flex;
        background-color: rgb(225,223,218);
        position: relative;
        z-index: -10;
        bottom: 0;
        margin: 0 auto;
        flex-direction: column-reverse;
        justify-content: space-between;
        column-gap: 4.17vw;
        /* border: red solid; */
    }
    
    .narodi-i-priroda {
        position: relative;
        background-image: url("./../images/robert-arnar-bdFQK43dNno-unsplash 1.png");
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        padding-bottom: 0%;
        margin-bottom: 9.38vw;
        /* border: blue solid; */
    }

    .narodi-i-interview {
        background-image: url("./../images/robert-arnar-2M3aUTHwhoY-unsplash 1.png");
        background-repeat: no-repeat;
        background-size: 100%;
        height: 100%;
        padding-bottom: 0%;
        /* border: blue solid; */
    }

    .text-box-footer {
        max-width: 63vw;
        max-height: 37.29vw;
        padding: 8.13vw 37.5vw 19.17vw 2.71vw;
        color: white;
        font-size: 2.50vw;
        text-transform: uppercase;
        font-family: 'Roboto';
        font-weight: 700;
        letter-spacing: 0.15em;
        line-height: 116.7%;
    }
    
    .text-box-footer a {
        color: white;
        font-size: 2.08vw;
        font-weight: 400;
    }
    
    .text-box-footer p {
        margin-bottom: 1.46vw;
        line-height: 1.2em;
        color: white;
        width: 20.21vw;
        margin-bottom: 3.13vw;
    }
    
    .footer-text {
        display: inline-block;
        width: 112.50vw;
    }
    
    .footer-img {
        display: block;
        /* border: yellow solid; */
        width: 62.50vw;
        margin: 0 auto;
        padding-left: 0;
    }

    .img-footer {
        max-width: 61.67vw;
        max-height: 37.29vw;
    }
    
    .footer-text {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 1.25vw;
        line-height: 116.7%;
        letter-spacing: 15%;
        display: flex;
        justify-content: center;
        width: auto;
        /* border: blueviolet solid; */
    }
    
    .footer-text p {
        font-size: 2.92vw;
    }
    
    .keeper {
        max-width: 61.67vw;
        height: auto;
        word-wrap: break-word;
        padding-left: 0%;
        padding-top: 2.50vw;
        /* border: black solid; */
        margin-bottom: 3.75vw;
    }
    
    .keeper :first-child , .keeper :nth-child(2), .review , .keeper :last-child {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 2.08vw;
        line-height: 116.7%;
        letter-spacing: 15%;
        margin: 0% auto;
    }

    .review {
        margin-top: 2.08vw;
    }
    
    .keeper :nth-child(2), .review, .keeper :last-child {
        font-size: 1.67vw;
        margin-bottom: 0%;
        /* border: green solid 2px; */
    }

    .keeper :nth-child(2) {
        margin-top: 2.08vw;
        font-size: 1.4vw;
    }
    
    .keeper :nth-child(4) {
        margin-top: 5vw;
        font-size: 1.4vw;
    }


/*для того, чтобы упростить себе жизнь с текстом на главной странице */

    .box-hide {
        display: none;
    }
}










