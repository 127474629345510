.content-box {
    max-width: 100vw;
    margin: 0 auto;
}

.unifying1, .unifying2, .unifying3 {
    display: flex;
    margin-left: 2%;
}

.unifying1 {
    max-width: 67.5vw;
    max-height: 49.07vh;
}

.unifying2 {
    max-width: 67.68vw;
    max-height: 49.36vh;
}

.unifying3 {
    max-width: 67.5vw;
    max-height: 49.64vh;
}

.text-box1 {
    max-height: 44.77vh ;
    background-color: rgb(110, 128, 122);
    word-wrap: break-word;
}

.content-box .text-box1:nth-child(odd) {
    left: 0;
    top: 2.3vw;
    position: relative;
}

.content-box .text-box1:nth-child(even) {
    right: 4.1%;
    bottom: -2vw;
    position: relative;
}

.content1 {
    margin-top: 10.5%;
    margin-bottom: 5.5%;
}

.unifying1 .box-img1 {
    z-index: -1;
    position: relative;
    top: 3.3vw;
    left: -4%;
    width: 172%;
    overflow: hidden;
}

.unifying1 .box-img1 img, .unifying2 .box-img1 img, .unifying3 .box-img1 img {
   margin-left: -16%;
}

.unifying2 .box-img1 {
    z-index: -1;
    position: relative;
    top: 3vw;
    width: 239%;
    overflow: hidden;
}

.unifying3 .box-img1 {
    z-index: -1;
    position: relative;
    left: -4%;
    top: 3.3vw;
    width: 270%;
    overflow: hidden;
}

.content1, .content2, .content3, .content4 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.unifying1 p, .unifying2 p, .unifying3 p {
    padding: 19% 2% 5% 3.5%;
    color: rgb(247, 244, 238);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.99vw;
    line-height: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.unifying2 p {
    -webkit-line-clamp: 6;
    letter-spacing: 0.1em;
    padding: 14% 1% 2% 2.5%;
}

.unifying3 p {
    -webkit-line-clamp: 8;
    letter-spacing: 0.1em;
    padding: 13% 4% 5% 3.5%;
}

.text-box1 a{
    font-size: 1.07vw;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: white;
    margin-left: 3.5%;
}

.unifying2 .text-box1 :first-child {
    margin-bottom: 5.4%;
}

.content2 a {
    margin-left: 2.5%;
}

.content2 {
    margin-bottom: 5.5%;
}

/*                                                              Цитата                                                      */

.quote0 {
    text-align: center;
}

.quotation {
    width: 53.4vw;
    position: relative;
}

.decoration1 , .decoration2 {
    padding: 8% 0;
    position: relative;
}

.decoration1 img {
    position: absolute;
    top: 1vw;
    left: 0.2%;
}

.decoration2 img {
    position: absolute;
    right: 0.2%;
    bottom: 1vw;
}

.line-box {
    width: 100%;
}

.line-box img {
    width: inherit;
}

.decoration1 .line-box {
    position: absolute;
    top: -16%;
}

.decoration2 .line-box {
    position: absolute;
    bottom: -20px;
    bottom: -18%;
}

.text-box2 {
    position: absolute;
    top: 4.2vw;
    left: 2vw;
    max-width: 49vw;
    font-size: 1.4vw;
}

.text-box2 p > *:not(:last-child) {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0em;
    font-weight: 400;
    font-size: 1.7vw;
    line-height: 116.7%;
}

.text-box-inner {
    display: inline-flex;
    justify-content: center; 
    max-height: 3vw;
}

.text-box-outer {
    display: inline-flex;
    justify-content: center;
    width: 53.3vw;
    margin-left: -4%;
    max-height: 2vw;
}

.author {
    margin-top: 11%;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 5%;
    line-height: 116.7%;
}


.content4 {
    margin-top: 13.5%;
    margin-bottom: 12.5%;
}

.content5 {
    margin-bottom: 2%;
}




/*                                                                НАША ПОЛИТИКА                                       */

.content5 {
    margin-left: 1.4%;
}

.common-box {
    display: flex;
    column-gap: 4.3%;
    padding: 0 1.4%;
}

.block1 {
    width: 32.3vw;
    word-wrap: break-word;
    position: relative;
    z-index: -1;
}

.subheading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.5%;
    text-transform: uppercase;
}

.subheading p, .block1 p, .block1 div {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.15em;
    line-height: 116.7%;
    font-size: 1.85vw;
}

.block1 p, .block1 div{
    display: inline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    font-size: 0.99vw;
}

.subcontainer {
    display: inline;
    position: absolute;
    top: 0;
    margin-top: 6.3%;
    width: 28.5vw;
    max-height: 19vh;
}

.subcontainer p, .subcontainer div p {
    font-size: 0.99vw;
    margin-bottom: 15%;
}


.content-box .mobile-text1 , .content-box .mobile-text2 , .content-box .mobile-text3, .content-box .mobile-a-3, .content-box .mobile-text4 {
    display: none;
}


@media (min-width: 1800px) {
    .unifying1 .box-img1 {
        z-index: -1;
        position: relative;
        top: 3vw;
        left: -4%;
        width: auto;
        overflow: visible;
    }
    
    .unifying1 .box-img1 img, .unifying2 .box-img1 img, .unifying3 .box-img1 img {
       margin-left: 0;
       max-width: 34vw;
    }
    
    .unifying2 .box-img1 {
        z-index: -1;
        position: relative;
        top: 3vw;
        width: auto;
        overflow: visible;
    }
    
    .unifying3 .box-img1 {
        z-index: -1;
        position: relative;
        left: -4%;
        top: 3.3vw;
        width: auto;
        overflow: visible;
    }

    .unifying1, .unifying2, .unifying3 {
        max-width: 87vw;
        margin-left: 3%;
    }

    .unifying1 p, .unifying2 p, .unifying3 p {
        padding: 9% 2% 5% 3.5%;
    }

    .content2 a {
        margin-left: 3.5%;
    }

    .unifying2 .text-box1 :first-child {
        margin-bottom: auto;
    }
}



@media (max-width: 480px) { 

    .content-box .mobile-text1, .content-box .mobile-text2, .content-box .mobile-text3, .content-box .mobile-a-3, .content-box .mobile-text4 {
        display: block;
    }

    .text-box1 .desktop-text, .desktop-text {
        display: none;
    }

    .content-box {
        margin-top: 0px;
        max-width: 100vw;
        margin: 0 auto;
    }
    
    .unifying1, .unifying2, .unifying3 {
        display: flex;
        position: relative;
        max-width: 90vw;
        width: 90vw;
    }
    
    .text-box1 {
        max-width: 61%;
        padding-bottom: 4%;
        background-color: rgb(110, 128, 122);
        word-wrap: break-word;
    }
    
    .content-box .text-box1:nth-child(odd) {
        left: 0;
        top: 0;
        position: relative;
    }
    
    .content-box .text-box1:nth-child(even) {
        left: 38.7%;
        bottom: auto;
        top: 0;
        position: relative;
    }
    
    .content1 {
        margin-bottom: 10%;
        margin-top: 12%;
    }

    .unifying1, .unifying2, .unifying3 {
        margin-left: 0;
    }
    
    .unifying1 .box-img1 {
        max-width: 38vw;
        height: 100%;
        z-index: -1;
        position: absolute;
        right: 0;
        left: auto;
        top: 5%;
        /* border: red solid; */
    }
    
    .unifying1 .box-img1 img, .unifying2 .box-img1 img, .unifying3 .box-img1 img {
       max-width: 45vw;
       height: 100%;
       object-fit: cover;
       /* border: blue solid; */
    }
    
    .unifying2 .box-img1 {
        max-width: 39vw;
        height: 100%;
        z-index: -1;
        position: absolute;
        top: 6%;
    }
    
    .unifying3 .box-img1 {
        max-width: 38vw;
        height: 100%;
        z-index: -1;
        position: absolute;
        left: auto;
        right: 0;
        top: 6%;
    }
    
    .content1, .content2, .content3, .content4 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .unifying1 p, .unifying2 p, .unifying3 p {
        /* padding: 6% 2% 4% 5%; */
        padding: 4% 2% 2% 5%;
        color: rgb(247, 244, 238);
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 2.5vw;
        line-height: auto;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        letter-spacing: 0.1em;
    }

    .mobile-a-3 {
        margin-top: 0;
    }

    .unifying2 p {
        -webkit-line-clamp: 7;
        letter-spacing: 0.1em;
        padding: auto; 
    }
    
    .unifying3 p {
        -webkit-line-clamp: 7;
        letter-spacing: 0.1em;
        padding: auto;
    }
    
    .text-box1 a{
        font-size: 2.3vw;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: white;
        margin-left: 5%;
    }
    
    .unifying2 .text-box1 :first-child {
        margin-bottom: 0px;
    }

    .a-content2 {
        margin-top: 0;
    }
    
    .content2 a {
        margin-left: 5%;
    }
    
    .content2 {
        margin-bottom: 10%;
    }

    /*                                                     Content4                                                     */

    .content4 {
        width: 100vw;
    }


    .mobile-text4 {
        max-width: 60vw;
        word-wrap: break-word;
        margin-top: -46%;
        margin-left: 14%;
    }

    .mobile-text4 div {
        font-size: 3.7vw;
        letter-spacing: 0.13em;
        text-align: center;
    }

    .text4-2 {
        margin-top: -3%;
    }
    
    .content4 .quotation {
        width: auto;
        position: relative;
    }
    
    .content4 .decoration1 , .content4 .decoration2 {
        position: relative;
    }
    
    .decoration1 img {
        position: absolute;
        left: 1%;
        top: 2%;
        width: 10vw;
    }
    
    .decoration2 img {
        position: absolute;
        right: 1%;
        bottom: 2%;
        width: 10vw;
    }

    .decoration2 {
        width: 80vw;
    }
    
    .content4 .line-box {
        width: 79vw;
    }

    .decoration1 .line-box img , .decoration2 .line-box img {
        width: 79vw;
        height: 0.5vw;
    }

    .decoration1 , .decoration2 {
        padding: 13% 0;
    }
    
    /* .decoration1 .line-box {
        position: absolute;
        top: -20px;
    } */
    
    /* .decoration2 .line-box {
        position: absolute;
        left: 5px;
        bottom: -20px;
    } */
    
    /* .text-box2 {
        position: absolute;
        top: 61px;
        left: 0px;
        height: 173px;
        width: 308px;
    } */
    
    .text-box2 p > *:not(:last-child) {
        font-family: 'Roboto', sans-serif;
        letter-spacing: 0em;
        font-weight: 400;
        font-size: 0.99vw;
        line-height: 116.7%;
        border: red solid;
    }
    
    .text-box-inner {
        display: inline-flex;
        justify-content: center;
        letter-spacing: 0.1em;
        width: 100%;
        border: rebeccapurple solid;
    }
    
    /* .text-box-outer {
        display: inline-flex;
        justify-content: center;
        width: 308px;
        margin-left: 0%;
        border: solid 2px blue;
        max-height: 30px;
    } */
    
    .author {
        margin-top: 9%;
        font-weight: 800;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 5%;
        line-height: 116.7%;
    }
    
    
    .content4 {
        margin: 23% auto;
    }
    
    .content5 {
        margin-bottom: 0px;
    }
    
    
    
    
    /*                                                                НАША ПОЛИТИКА                                       */
    
    .content5 {
        margin-left: 0px;
    }
    
    .common-box {
        display: flex;
        justify-content: space-between;
        row-gap: 15vw;
        padding: 0 0px;
        padding-bottom: 17%;
        flex-direction: column;
    }

    .block1 {
        width: 68vw;
        padding-bottom: 24%;
        word-wrap: break-word;
        position: relative;
        z-index: -1;
        margin: 0 auto;
        letter-spacing: 0.13em;
    }

    .common-box .block1:nth-child(1) {
        padding-bottom: 16%;
    }

    .block1 img {
        width: 83%;
    }
    
    .subheading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18%;
        text-transform: uppercase;
    }
    
    .subheading p, .block1 p, .block1 div {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        letter-spacing: 0.15em;
        line-height: 116.7%;
        font-size: 4.99vw;
    }
    
    .block1 p, .block1 div{
        display: inline;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 11;
        -webkit-box-orient: vertical;
        font-size: 0.99vw;
    }
    
    .subcontainer {
        display: inline;
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        margin: 5% 0;
    }
    
    .subcontainer p, .subcontainer div p {
        font-size: 3.5vw;   
        margin-bottom: 8%;
    }

    .subcontainer .subcontainer-title {
        font-weight: 700;
        text-transform: capitalize;
    }
}
